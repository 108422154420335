import { useEffect, useState } from "react";

export const useLocalStorage = (key: string, initialValue: any) => {
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedValue = localStorage.getItem(key);
      if (storedValue !== null) {
        setState(JSON.parse(storedValue));
      }
    }
  }, [key]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        setState(JSON.parse(event.newValue as string));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key]);

  const setLocalStorageState = (value: any) => {
    setState(value);
    if (typeof window !== "undefined") {
      localStorage.setItem(key, JSON.stringify(value));
      // Trigger a storage event to notify other windows/tabs of the change
      window.dispatchEvent(new StorageEvent("storage", { key }));
    }
  };

  return [state, setLocalStorageState];
};

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brand-slug-index-tsx": () => import("./../../../src/pages/{Brand.slug}/index.tsx" /* webpackChunkName: "component---src-pages-brand-slug-index-tsx" */),
  "component---src-pages-buscar-tsx": () => import("./../../../src/pages/buscar.tsx" /* webpackChunkName: "component---src-pages-buscar-tsx" */),
  "component---src-pages-comprar-catalogo-brand-slug-index-tsx": () => import("./../../../src/pages/comprar/catalogo/{Brand.slug}/index.tsx" /* webpackChunkName: "component---src-pages-comprar-catalogo-brand-slug-index-tsx" */),
  "component---src-pages-comprar-catalogo-index-tsx": () => import("./../../../src/pages/comprar/catalogo/index.tsx" /* webpackChunkName: "component---src-pages-comprar-catalogo-index-tsx" */),
  "component---src-pages-comprar-index-tsx": () => import("./../../../src/pages/comprar/index.tsx" /* webpackChunkName: "component---src-pages-comprar-index-tsx" */),
  "component---src-pages-comprar-productos-sales-watch-slug-index-tsx": () => import("./../../../src/pages/comprar/productos/{SalesWatch.slug}/index.tsx" /* webpackChunkName: "component---src-pages-comprar-productos-sales-watch-slug-index-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-magazine-index-tsx": () => import("./../../../src/pages/magazine/index.tsx" /* webpackChunkName: "component---src-pages-magazine-index-tsx" */),
  "component---src-pages-model-slug-index-tsx": () => import("./../../../src/pages/{Model.slug}/index.tsx" /* webpackChunkName: "component---src-pages-model-slug-index-tsx" */),
  "component---src-pages-productos-watch-slug-formulario-index-tsx": () => import("./../../../src/pages/productos/{Watch.slug}/formulario/index.tsx" /* webpackChunkName: "component---src-pages-productos-watch-slug-formulario-index-tsx" */),
  "component---src-pages-productos-watch-slug-valoracion-tsx": () => import("./../../../src/pages/productos/{Watch.slug}/valoracion.tsx" /* webpackChunkName: "component---src-pages-productos-watch-slug-valoracion-tsx" */),
  "component---src-pages-returns-tsx": () => import("./../../../src/pages/returns.tsx" /* webpackChunkName: "component---src-pages-returns-tsx" */),
  "component---src-pages-servicios-tsx": () => import("./../../../src/pages/servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-tsx" */),
  "component---src-pages-shop-seo-magazine-slug-index-tsx": () => import("./../../../src/pages/shop/{SeoMagazine.slug}/index.tsx" /* webpackChunkName: "component---src-pages-shop-seo-magazine-slug-index-tsx" */),
  "component---src-pages-shop-seo-watch-slug-index-tsx": () => import("./../../../src/pages/shop/{SeoWatch.slug}/index.tsx" /* webpackChunkName: "component---src-pages-shop-seo-watch-slug-index-tsx" */),
  "component---src-pages-sobre-nosotros-tsx": () => import("./../../../src/pages/sobre-nosotros.tsx" /* webpackChunkName: "component---src-pages-sobre-nosotros-tsx" */),
  "component---src-pages-tasacion-gratuita-brand-slug-index-tsx": () => import("./../../../src/pages/tasacion-gratuita/{Brand.slug}/index.tsx" /* webpackChunkName: "component---src-pages-tasacion-gratuita-brand-slug-index-tsx" */),
  "component---src-pages-tasacion-gratuita-buscar-tsx": () => import("./../../../src/pages/tasacion-gratuita/buscar.tsx" /* webpackChunkName: "component---src-pages-tasacion-gratuita-buscar-tsx" */),
  "component---src-pages-tasacion-gratuita-formulario-exito-tsx": () => import("./../../../src/pages/tasacion-gratuita/formulario/exito.tsx" /* webpackChunkName: "component---src-pages-tasacion-gratuita-formulario-exito-tsx" */),
  "component---src-pages-tasacion-gratuita-index-tsx": () => import("./../../../src/pages/tasacion-gratuita/index.tsx" /* webpackChunkName: "component---src-pages-tasacion-gratuita-index-tsx" */),
  "component---src-pages-tasacion-gratuita-model-slug-index-tsx": () => import("./../../../src/pages/tasacion-gratuita/{Model.slug}/index.tsx" /* webpackChunkName: "component---src-pages-tasacion-gratuita-model-slug-index-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-magazine-magazine-slug-comprar-article-page-tsx": () => import("./../../../src/templates/magazine/{Magazine.slug}/ComprarArticlePage.tsx" /* webpackChunkName: "component---src-templates-magazine-magazine-slug-comprar-article-page-tsx" */),
  "component---src-templates-magazine-magazine-slug-default-article-page-tsx": () => import("./../../../src/templates/magazine/{Magazine.slug}/DefaultArticlePage.tsx" /* webpackChunkName: "component---src-templates-magazine-magazine-slug-default-article-page-tsx" */)
}


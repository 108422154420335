import React from 'react';
import { AnimatePresence } from "framer-motion"
import { Script } from 'gatsby';

export const wrapPageElement = ({ element }) => {
  return (
    <AnimatePresence key="animate-presence" mode='wait'>
      {element}

      {process.env.NODE_ENV === 'production' && (<><Script type="text/javascript">
        {`(function(window, document, dataLayerName, id) {
          window[dataLayerName] = window[dataLayerName] || [], window[dataLayerName].push({ start: (new Date).getTime(), event: "stg.start" });var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
        function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString();f="; SameSite=Strict"}document.cookie=a+"="+b+d+f+"; path=/"}
        var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
        tags.async=!0,tags.src="https://watchproject21.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
        !function(a,n,i){a[n] = a[n] || {};for(var c=0;c<i.length;c++)!function(i){a[n][i] = a[n][i] || {}, a[n][i].api = a[n][i].api || function () { var a = [].slice.call(arguments, 0); "string" == typeof a[0] && window[dataLayerName].push({ event: n + "." + i + ":" + a[0], parameters: [].slice.call(arguments, 1) }) }}(i[c])}(window,"ppms",["tm","cm"]);
})(window, document, 'dataLayer', '6b94720c-a204-4f2b-bdef-43898b6c6b8e');
        `}
      </Script>
        <Script type="text/javascript">
          {`// var et_pagename = "";
          // var et_areas = "";
          // var et_tval = 0;
          // var et_tsale = 0;
          // var et_tonr = "";
          // var et_basket = "";`
          }
        </Script>
        <Script id="_etLoader" type="text/javascript" data-block-cookies="true" data-secure-code="0Tmegg" src="//code.etracker.com/code/e.js" async></Script></>)}
    </AnimatePresence >
  )
}

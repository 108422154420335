/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/styles/global.css"
import { LOCAL_STORAGE_KEY_HOME_ANIMATION_VISIBLE } from "./src/components/Atoms/Icons/Logo"

export { wrapPageElement } from "./gatsby-shared"

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0)
  return false
}

const scrollTo = id => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - 20)
  return false
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.hash) {
    window.setTimeout(scrollTo(location.hash), 10)
  }

  if (!prevLocation) {
    localStorage.setItem(
      LOCAL_STORAGE_KEY_HOME_ANIMATION_VISIBLE,
      JSON.stringify(true)
    )
  } else {
    localStorage.setItem(
      LOCAL_STORAGE_KEY_HOME_ANIMATION_VISIBLE,
      JSON.stringify(false)
    )
  }
}
